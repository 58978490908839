.password-input-container {
    position: relative;

    &_input {
        border: none;
        border-bottom: 1px solid #ebebeb;
        outline: none;
        width: 100%;
        padding: 10px 3px;
        font-family: 'Helvetica Neue';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: 2px;
        color: var(--grey-color);
        margin-bottom: 25px;
    }
    &_img {
        position: absolute;
        right: 0;
        top: 10px;
        cursor: pointer;
    }
}
