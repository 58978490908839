.project-card-container {
    margin: 0 0 10px 0;
    border-radius: 8px;
    background-size: cover;
    background-repeat: no-repeat;
    width: 48%;
    height: 165px;

    &__content {
        cursor: pointer;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
    }

    &_unit-amount {
        font-family: 'Helvetica Neue';
        font-style: normal;
        font-weight: 700;
        font-size: 50px;
        text-align: center;
        color: var(--white-color);
    }

    &_unit {
        font-size: 16px;
    }

    &_name {
        font-family: 'Helvetica Neue';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 28px;
        text-align: center;
        letter-spacing: 0.02em;
        color: var(--white-color);
        text-transform: capitalize;
    }
}
