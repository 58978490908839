.terms-of-use__container {
    &__header {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        cursor: pointer;
        padding: 16px 16px;
    }
    &__text-container {
        width: 100%;
        display: flex;
        justify-content: space-around;
        justify-items: center;
        align-items: center;
    }
    &__text {
        font-family: 'Helvetica Neue Bold';
        font-weight: 700;
        font-size: 20px;
        letter-spacing: 1px;
        color: black;
    }
    &__border {
        border: 1px solid #ebebeb;
    }
    &__body {
        margin: 18px 16px 0 16px;
        height: 100%;
    }
    &__first-text {
        font-family: 'Helvetica Neue';
        font-weight: 400;
        font-size: 14px;
        line-height: 25px;
        color: #979797;
        margin: 0 0 16px 0;
    }
    &_title {
        font-weight: 700;
        margin: 0 0 4px 0;
    }
}
