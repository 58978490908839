.container {
    background-image: url('../../assets/images/start-screen.png');
    padding-top: 32px;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    padding-bottom: 35px;

    &__close-wrapper {
        padding: 0 39px;
        display: flex;
        justify-content: flex-end;
    }
    &_close {
        color: var(--white-color);
        font-family: 'Helvetica Neue';
        font-style: normal;
        font-weight: 700;
        font-size: 28px;
        line-height: 48px;
    }

    &__body {
        margin-top: 10%;
        padding: 0 39px 0;
    }

    &__logo-container {
        text-align: center;
    }
    &_logo {
        width: 70%;
        height: 100%;
    }

    &_title {
        font-family: 'Helvetica Neue Bold';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        letter-spacing: 0.06em;
        text-transform: uppercase;
        color: var(--white-color);
        margin-top: 40px;
    }

    &__social-buttons-container {
        display: flex;
        flex-direction: column;
        width: 80%;
        margin: 0 auto;
        margin-top: 30px;
    }

    &__or-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 20px;
    }
    &__or-line {
        height: 1px;
        width: 50%;
        background-color: var(--white-color);
    }
    &_or-text {
        font-family: 'Helvetica Neue Bold';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 30px;
        text-align: center;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: var(--white-color);
        margin: 0 10px;
    }

    &__sign-up-wrapper {
        width: 80%;
        margin: 0 auto;
        margin-top: 20px;
    }

    &_description {
        color: var(--white-color);
        font-family: 'Helvetica Neue';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 26px;
        text-align: center;
        margin-top: 20px;
    }

    &_already-text {
        font-family: 'Helvetica Neue';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 26px;
        text-align: center;
        color: var(--white-color);
        margin-top: 20px;
    }

    &_sign-in {
        font-weight: 600;
        cursor: pointer;
    }
}
.container-close {
    cursor: pointer;
}
