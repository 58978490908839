.details-container-slider {
    height: 100%;
    width: 100%;
    &__wrapper {
        display: flex;
        flex-direction: column;
        height: 100%;
        position: relative;
        @media screen and (max-height: 700px) {
            height: 83%;
        }
    }
    &_container {
        position: relative;
    }
    &_img {
        width: 100%;
        height: 25vh;
    }
}
.details-container-slider_container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    &_title {
        position: absolute;
        top: 35%;
        color: white;
        font-family: 'Helvetica Neue Bold';
        font-weight: 700;
        font-size: 20px;
        letter-spacing: 0.14em;
    }
    &_title_4 {
        position: absolute;
        top: 35%;
        color: white;
        font-family: 'Helvetica Neue Bold';
        font-weight: 700;
        font-size: 20px;
        letter-spacing: 0.14em;
        text-transform: uppercase;
    }
    &_subtitle {
        text-align: center;
        position: absolute;
        top: 50%;
        max-width: 290px;
        color: white;
        font-family: 'Helvetica Neue Bold';
        font-weight: 700;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 0.18em;
    }
    &_logo {
        position: absolute;
        top: 70%;
    }
}
.details-container-slider_container_4 {
    &__content {
        display: flex;
        width: 100%;
        margin: 30px 0 0 10px;
        gap: 26px;
        &_img {
            width: 74px;
            height: 74px;
        }
        &__text {
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            &__title {
                padding: 5px 0 0 0;
                font-family: 'Helvetica Neue Bold';
                font-style: normal;
                font-weight: 700;
                font-size: 14px;
                line-height: 14px;
                letter-spacing: 2px;
                text-transform: uppercase;
                align-self: flex-start;
            }
            &__text {
                padding: 5px 0 0 0;
                align-self: flex-start !important;
                font-family: 'Helvetica Neue';
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 19px;
                letter-spacing: 0.06em;
                color: #322c2c;
                max-width: 70%;
            }
        }
    }
}
.firsttt {
    height: 100%;
    .slick-list {
        padding: 0 0 0 0 !important;
        height: 100%;
        .slick-slide > div > div {
            text-align: left !important;
        }
    }
    .slick-dots > li {
        width: 30px;
    }
    .slick-dots {
        position: absolute;
        bottom: 0;
        margin: 30px auto;
        width: 100%;
        text-align: center;
        padding: 0;
    }
}
.details-container-slider__content {
    margin: 70px 0 0 0;
    &_title {
        text-align: center;
        font-family: 'Helvetica Neue Bold';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 14px;
        letter-spacing: 2px;
        text-transform: uppercase;
        color: black;
    }
    &_text {
        text-align: center;
        margin: 20px 0 0 0;
        font-family: 'Helvetica Neue';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        letter-spacing: 0.04em;
        color: #322c2c;
        white-space: pre-line;
    }
}
.details-container__closee {
    &-wrapper {
        position: absolute;
        z-index: 1;
        right: 0;
        top: 0;
        padding: 15px 15px;
        display: flex;
        justify-content: flex-end;
    }
    &-close {
        cursor: pointer;
    }
}
