.transaction-date-container {
    &__date-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__border {
        background: #9b9b9b;
        height: 1px;
        width: 33%;
    }

    &_title {
        font-family: 'Helvetica Neue';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 26px;
        text-align: center;
        letter-spacing: 0.02em;
        color: var(--grey-color);
    }

    &__transactions {
        padding: 0 15px;
        border-radius: 6px;
    }
}
