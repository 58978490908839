.header {
    background-color: var(--primary-color);
    display: flex;
    flex-direction: column;
    padding: 0px 0 0 20px;
    overflow-x: hidden;
    @media (max-width: 500px) {
        justify-content: flex-start;
        width: auto;
    }
    &__header-container {
        background-color: var(--primary-color);
        display: flex;
        margin: 0 auto;
        position: relative;
        max-width: 1440px;
        margin-right: auto;
        margin-left: auto;
        width: 100%;
        justify-content: flex-end;
        height: 60px;
        align-items: center;
        @media (min-width: 2340) {
            right: 18.5%;
        }
        @media (min-width: 1950px) {
            width: 100%;
        }
        @media (max-width: 500px) {
            width: 100%;
            justify-content: center;
        }
    }
    &__auth-container {
        position: absolute;
        right: -1%;
        @media (max-width: 1500px) {
            right: 0.5%;
        }
    }

    &_title {
        position: absolute;
        left: 40%;
        color: var(--white-color);
        font-family: 'Helvetica Neue';
        font-style: normal;
        font-weight: 500;
        font-size: 28px;
        line-height: 36px;
        @media (min-width: 1950px) {
            left: 43%;
        }
        @media (max-width: 1110px) {
            left: 37%;
        }
        @media (max-width: 970px) {
            left: 34%;
        }
        @media (max-width: 700px) {
            left: 30%;
        }
        @media (max-width: 570px) {
            left: 26%;
        }
        @media (max-width: 525px) {
            left: 0;
        }
        @media (max-width: 410px) {
            left: -5%;
        }
        @media (max-width: 375px) {
            left: 3%;
            font-size: 22px;
        }
        @media (max-width: 345px) {
            left: -6%;
            font-size: 22px;
        }
        @media (max-width: 320px) {
            left: -6%;
            font-size: 19.5px;
        }
    }
}
