.delete-account-container {
    width: 100%;
    display: flex;
    justify-content: center;
    background: rgba(0, 0, 0, 0.4);
    backdrop-filter: blur(3px);
    align-items: center;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;

    &__delete {
        text-align: center;
        width: 70%;
        min-width: 280px;
        min-height: 270px;
        background-color: white;
        padding: 40px 30px;
        height: 30%;
    }
    &__buttons {
        display: flex;
        justify-content: center;
    }
    &__button {
        width: 135px;
        margin-left: 13px;
    }
    &__title {
        margin-bottom: 30px;
        font-family: 'Helvetica Neue Bold';
        font-weight: 700;
        font-size: 20px;
        letter-spacing: 0.02em;
        color: black;
    }
    &__text {
        text-align: center;
        margin: 0 0 36px 0;
        height: 72px;
        font-family: 'Helvetica Neue';
        font-weight: 400;
        font-size: 20px;
        line-height: 24px;
        color: black;
    }
}
