.photo-button {
    cursor: pointer;
    position: absolute;
    bottom: 14px;
    background: rgba(255, 255, 255, 0.55);
    border-radius: 142px;
    display: flex;
    flex-direction: row;
    padding: 7px 10px;
    &__image-back {
        background-color: var(--white-color);
        border-radius: 50%;
        padding: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    &__image-back:not(:last-child) {
        margin-right: 15px;
    }
    &_icon-item {
        max-width: 46px;
        border: 3px solid;
        border-radius: 50%;
        border-color: #466237;
    }
}
.photo-button-details {
    position: absolute;
    bottom: 14px;
    background: rgba(255, 255, 255, 0.55);
    border-radius: 142px;
    display: flex;
    flex-direction: row;
    padding: 7px 10px;
    &__image-back {
        background-color: var(--white-color);
        border-radius: 50%;
        padding: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    &__image-back:not(:last-child) {
        margin-right: 15px;
    }
    &_icon-item {
        cursor: pointer;
        max-width: 46px;
        border: 3px solid;
        border-radius: 50%;
        border-color: #466237;
    }
}
