.finish-container {
    height: 100%;
    background-image: url('../../assets/images/start-screen.png');
    padding-top: 32px;
    background-repeat: no-repeat;
    background-size: cover;

    &__close-wrapper {
        cursor: pointer;
        padding: 0 39px;
    }
    &_close {
        color: var(--white-color);
        font-family: 'Helvetica Neue';
        font-style: normal;
        font-weight: 700;
        font-size: 28px;
        line-height: 48px;
    }

    &__body {
        margin-top: 10%;
        padding: 0 39px 0;
        height: 80%;
    }

    &__content {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    &_title {
        font-family: 'Helvetica Neue';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 30px;
        text-align: center;
        letter-spacing: 0.06em;
        text-transform: uppercase;
        color: var(--white-color);
    }

    &_info {
        font-family: 'Helvetica Neue';
        font-style: normal;
        font-weight: 700;
        font-size: 13px;
        line-height: 26px;
        text-align: center;
        letter-spacing: 0.02em;
        color: var(--white-color);
        margin-top: 30px;
    }

    &_underline {
        text-decoration: underline;
        color: var(--white-color);
    }

    &__button-wrapper {
        width: 60%;
        margin: 0 auto;
        margin-top: 40px;
    }
}
