.transaction-info-container {
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.4);
    backdrop-filter: blur(5px);
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    &__modal {
        width: 80%;
        background-color: var(--white-color);
        padding: 50px 20px;
    }

    &_title {
        font-family: 'Helvetica Neue Bold';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 30px;
        text-align: center;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: var(--black-color);
        margin-bottom: 35px;
    }

    &_desc {
        font-family: 'Helvetica Neue';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 26px;
        text-align: center;
        letter-spacing: 0.02em;
        color: var(--black-color);
        margin-bottom: 35px;
    }
}
