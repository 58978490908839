.forgot-container {
    height: 100%;

    &__hero-wrapper {
        height: 40%;
        background-image: url('../../assets/images/hero-image.png');
        width: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        display: flex;
        padding-top: 35px;
    }

    &__close-wrapper {
        cursor: pointer;
        padding: 0 39px;
    }

    &__body {
        padding: 30px 30px 0;
        height: 35%;
    }
    &_title {
        font-family: 'Helvetica Neue';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 30px;
        text-align: center;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: var(--black-color);
    }
    &_info {
        font-family: 'Helvetica Neue';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 26px;
        text-align: center;
        color: var(--black-color);
        margin-bottom: 40px;
        margin-top: 10px;
    }

    &__bottom-wrapper {
        padding: 0 30px;
    }
}

.forgot-container__bottom-wrapper > button > p {
    text-transform: uppercase;
}
