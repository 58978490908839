.modal {
	width: 100%;
	overflow-x: hidden;
	background-color: rgba(255, 255, 255, 0.7);
	display: flex;
	justify-content: flex-end;
	height: 100%;
	top: 0;
	right: 0;
	position: fixed;
	z-index: 1000;
	&__modal-container_2 {
		width: 22%;
		background-color: var(--white-color);
		overflow-y: scroll;
		border-left: 3px solid var(--primary-color);
		overflow-x: hidden;
		min-height: 790px;
		transition: 0.4s;
		transform: scale(0, 0);
		transform-origin: 100% 0;
		@media screen and (max-width: 1490px) {
			width: 29%;
		}
		@media screen and (max-width: 1750px) {
			width: 30%;
		}
		@media screen and (max-width: 1650px) {
			width: 29%;
		}

		@media screen and (max-width: 1550px) {
			width: 28%;
		}
		@media screen and (max-width: 1400px) {
			width: 27.5%;
		}

		@media screen and (max-width: 1100px) {
			width: 35%;
		}

		@media screen and (max-width: 900px) {
			width: 45%;
		}

		@media screen and (max-width: 800px) {
			width: 50%;
		}

		@media screen and (max-width: 700px) {
			width: 50.5%;
		}

		@media screen and (max-width: 600px) {
			width: 70%;
		}

		@media screen and (max-width: 500px) {
			width: 100%;
		}
	}
	&__modal-container_1 {
		cursor: pointer;
		width: 78%;
		transition: 0.4s;
		opacity: 0;
		@media screen and (max-width: 1750px) {
			width: 67%;
		}
		@media screen and (max-width: 1490px) {
			width: 71%;
		}
		@media screen and (max-width: 1400px) {
			width: 72.5%;
		}
		@media screen and (max-width: 1100px) {
			width: 65%;
		}

		@media screen and (max-width: 900px) {
			width: 40%;
		}

		@media screen and (max-width: 600px) {
			width: 30%;
		}
		@media screen and (max-width: 800px) {
			width: 50%;
		}
		@media screen and (max-width: 700px) {
			width: 49.5%;
		}
		@media screen and (max-width: 500px) {
			width: 0;
		}
	}

	&.active &__modal-container_2 {
		transform: scale(1, 1);
	}
	&.active &__modal-container_1 {
		opacity: 1;
	}
}
