.thanks-container {
    width: 100%;
    height: 100%;
    background-image: url('../../assets/images/thanks-bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;

    &__content {
    }

    &_thanks-title {
        text-align: center;
        width: 55%;
        margin: 0 auto;
        font-family: 'Helvetica Neue';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 30px;
        text-align: center;
        letter-spacing: 0.06em;
        color: var(--white-color);
    }

    &_thanks-name {
        font-family: 'Helvetica Neue';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 30px;
        text-align: center;
        letter-spacing: 0.06em;
        text-transform: uppercase;
        color: var(--white-color);
        margin-top: 20px;
    }
}
