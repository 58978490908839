@font-face {
    font-family: 'Helvetica Neue';
    src: url('../assets/fonts/Helvetica.ttf');
}

@font-face {
    font-family: 'Helvetica Neue Bold';
    src: url('../assets/fonts/Helvetica-Bold.ttf');
}

@font-face {
    font-family: 'Helvetica Neue Normal';
    src: url('../assets/fonts/helvetica-light-587ebe5a59211.ttf');
}
