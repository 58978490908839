.transparent {
    background-color: var(--white-color);

    &_title {
        color: var(--primary-color);
        font-family: 'Helvetica Neue';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
    }
}

.primary {
    background-color: var(--primary-color);
}
.button {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 30px;
    border: none;
    padding: 8px 24px;
    width: 100%;
}
.black-outlined {
    background-color: var(--black-color);
    border: 1px solid var(--white-color);
    width: 100%;
    justify-content: center;
    padding: 16px;

    &_title {
        color: var(--white-color);
        font-family: 'Helvetica Neue';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        text-transform: uppercase;
    }
}

.black {
    background-color: var(--black-color);
    width: 100%;
    justify-content: center;
    padding: 16px;

    &_title {
        color: var(--white-color);
        font-family: 'Helvetica Neue Bold';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        text-transform: uppercase;
    }
}
.white {
    background-color: var(--white-color);
    width: 100%;
    justify-content: center;
    padding: 16px;
    border: 1px solid #000;

    &_title {
        color: var(--black-color);
        font-family: 'Helvetica Neue';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
    }
}

.disabled {
    background-color: #9b9b9b;
}

.disabled > p {
    color: var(--white-color);
}

.transparent_image {
    width: 35px;
    height: 35px;
    margin-left: 20px;
    border-radius: 50px;
    object-fit: contain;
    background-color: white;
}
.transparent_icon {
    position: relative;
    left: 5px;
}
