.transaction-container {
    height: 100%;
    position: relative;

    &__header-container {
        display: flex;
        justify-content: space-between;
        padding: 19.5px 16px 9.5px 16px;
        border-bottom: 2px solid var(--light-gray-color);
    }

    &_header-title {
        font-family: 'Helvetica Neue Bold';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 30px;
        text-align: center;
        letter-spacing: 0.03em;
        text-transform: uppercase;
        color: var(--black-color);
    }
    &_header-info {
        cursor: pointer;
    }
    &__header-arrow {
        cursor: pointer;
    }

    &__content {
        padding: 20px 16px;
    }
}

.transaction-balance {
    padding: 16px 21px;
    border-bottom: 2px solid var(--light-gray-color);

    &__info {
        display: flex;
        margin: 8px;
        justify-content: space-between;
    }
}
