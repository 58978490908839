.follow-screen {
    display: flex;
    flex-direction: column;
    height: 100%;
    &_count {
        text-align: center;
        margin: 10px 0 10px 0;
        font-family: 'Helvetica Neue Bold';
        font-weight: 400;
        font-size: 14px;
        letter-spacing: 0.02em;
        color: black;
    }
    &_container-global {
        flex-wrap: wrap;
        display: flex;
        gap: 40px;
        height: 100%;
        margin: 0 15px;
    }
    &__container-global {
        height: fit-content;
        display: flex;
        flex-direction: column;
        margin: 0 15px 0 15px;
    }
    &__container {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin: 0 0 15px 0;
    }
    &__brand {
        cursor: pointer;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 40%;
        min-width: 100px;
        margin: 15px 0 0 0;
        &_img {
            width: 100%;
            height: 125px;
            border-radius: 6px;
            object-fit: cover;
        }
        &_text {
            text-align: center;
            margin: 5px 0 0 0;
            font-family: 'Helvetica Neue Bold';
            font-weight: 400;
            font-size: 14px;
            letter-spacing: 1px;
            color: black;
        }
        &_unfollow {
            position: absolute;
            top: 3%;
            right: 5%;
        }
    }
    &__new-brand {
        cursor: pointer;
        display: flex;
        flex-direction: column;
        align-items: center;
        align-self: flex-start;
        width: 40%;
        min-width: 100px;
        max-height: 135px;
        min-height: 135px;
        height: 80%;
        margin: 15px 0 0 0;
        border: 1px solid #9b9b9b;
        border-radius: 6px;
        &_title {
            width: fit-content;
            max-width: 88px;
            height: 60%;
            margin: 10px 0 0 0;
            color: #9b9b9b;
            font-family: 'Helvetica Neue Bold';
            font-size: 16px;
            letter-spacing: 0.1em;
            line-height: 23px;
            text-align: center;
            text-transform: uppercase;
        }
    }
}
