.corporate-details {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    width: 100%;
    height: 100%;
    &__backarrow {
        cursor: pointer;
        position: absolute;
        left: 4%;
        top: 2.5%;
        display: inline-block;
        z-index: 1;
    }
    &__image-container {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 30%;
        min-height: 280px;
    }
    &__img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    &__photo-name {
        position: absolute;
        font-style: normal;
        font-family: 'Helvetica Neue Bold';
        font-weight: 700;
        font-size: 22px;
        line-height: 30px;
        letter-spacing: 2px;
        text-transform: uppercase;
        text-align: center;
        padding: 0 10px 25px;
        color: #fff;
        z-index: 2;
    }
    &__follow-percent {
        color: black;
        display: flex;
        margin: 20px 0 20px 0;
        justify-content: center;
        gap: 70px;
    }
    &__follow {
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 5px;
        &_text {
            color: black;
            font-family: 'Helvetica Neue Bold';
            letter-spacing: 2px;
            font-weight: 700;
            font-size: 12px;
        }
    }
    &__percent {
        &_text {
            cursor: pointer;
            color: black;
            font-family: 'Helvetica Neue Bold';
            font-weight: 700;
            font-size: 12px;
            letter-spacing: 2px;
        }
    }
    &__percent-false {
        display: none;
    }
    &__description_false {
        height: fit-content;
        overflow: hidden;
        padding: 0 20px;
        min-height: 40px;
        p {
            display: none;
        }
        p:first-child {
            display: inline;
            color: black;
            font-family: 'Helvetica Neue';
            font-style: normal;
            font-weight: 400;
            font-size: 15px;
            line-height: 20px;
        }
        img {
            width: 100%;
            object-fit: cover;
        }
    }
    &__description_true {
        padding: 0 20px;
        p {
            color: black;
            font-family: 'Helvetica Neue';
            font-style: normal;
            font-weight: 400;
            font-size: 15px;
            line-height: 20px;
        }
        img {
            width: 100%;
            object-fit: cover;
        }
    }
    &__description__more {
        display: flex;
        justify-content: center;
        margin: 30px 0 0 0;
        gap: 3px;
        p {
            font-family: 'Helvetica Neue Bold';
            font-weight: 900;
            font-size: 15px;
            letter-spacing: 1px;
            cursor: pointer;
        }
        img {
            cursor: pointer;
        }
    }
    &__accreditations-container {
        margin: 30px 0 0 0;
        justify-content: center;
        p {
            text-align: center;
            font-family: 'Helvetica Neue Bold';
            font-weight: 700;
            font-size: 15px;
            letter-spacing: 2px;
            color: black;
        }
    }
    &__accreditations {
        margin: 25px 15px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 10px;
        &_icon {
            cursor: pointer;
            width: 74px;
            height: 74px;
        }
    }
    &__button {
        width: 90%;
        position: relative;
        bottom: 0;
        padding: 0 0 30px 0;
    }
}
