.menu {
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
}
.menu_profile-bg {
    position: relative;
    width: 100%;
    height: 120px;
    &_photo {
        width: 100%;
        height: 100%;
    }
}
.menu__porfile-info {
    position: absolute;
    top: 0;
    margin: 44px 0 0 0;
    width: 220px;
    height: 195px;
    text-align: center;
    &_image {
        width: 145px;
        height: 145px;
        border-radius: 100px;
        object-fit: contain;
        background-color: white;
    }
    &_name-points {
        margin: 20px 0 0 0;
        display: flex;
        width: 100%;
        height: 30px;
        justify-content: center;
        align-items: center;
        &__border {
            height: 16.5px;
            border: 1px solid rgba(0, 0, 0, 0.2);
        }
        &__name {
            font-family: 'Helvetica Neue Bold';
            font-style: normal;
            font-size: 20px;
            font-weight: 700;
            margin: 0 10px 0 0;
            text-transform: uppercase;
            letter-spacing: 0.002em;
            color: black;
        }
        &__points {
            display: flex;
            justify-content: space-between;
            justify-content: center;
            align-items: center;
            margin: 0 0 0 10px;
            align-self: center;
        }

        &__points-text {
            font-family: 'Helvetica Neue';
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 30px;
            text-align: center;
            color: #000000;
        }
    }
}
.menu__empty {
    width: 100%;
    height: 126px;
}
.menu__buttons-container {
    width: 100%;
}
.menu__buttons {
    cursor: pointer;
    display: flex;
    align-items: center;
    margin: 0 3px 0 10px;
    border: 0px solid #fff;
    border-bottom: 1px solid rgba(155, 155, 155, 0.2);
    background-color: white;
    width: 97%;
    height: 66px;
    text-decoration: none;
    &__icon {
        margin-right: 10px;
    }
    &__text {
        font-family: 'Helvetica Neue';
        font-weight: 400;
        font-size: 16px;
        letter-spacing: 2px;
        line-height: 19px;
        font-style: normal;
        color: black;
        text-decoration: none;
    }
}
.menu__backarrow {
    cursor: pointer;
    transform: rotate(0.5turn);
    position: absolute;
    left: 5%;
    top: 3%;
    display: inline-block;
}

.menu__buttons:hover {
    background-color: #d0dbd8;
}
