.welcome-container {
    width: 100%;
    height: auto;
    background-image: url('../../assets/images/welcomebg.png');
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    min-height: 700px;

    &__wrapper {
        padding-top: 45%;
    }

    &_title {
        font-family: 'Helvetica Neue Bold';
        font-style: normal;
        font-size: 30px;
        line-height: 37px;
        text-align: center;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: var(--white-color);
        margin-bottom: 15px;
    }

    &_desc {
        font-family: 'Helvetica Neue';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 30px;
        text-align: center;
        letter-spacing: 0.02em;
        color: var(--white-color);
        margin-bottom: 25px;
    }

    &__points-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 40%;
        margin: 0 auto;
        margin-bottom: 24px;
    }

    &_points-number {
        font-family: 'Helvetica Neue Bold';
        font-style: normal;
        font-weight: 700;
        font-size: 26px;
        line-height: 32px;
        text-align: center;
        letter-spacing: 0.02em;
        color: var(--white-color);
    }

    &_points-text {
        font-family: 'Helvetica Neue Bold';
        font-style: normal;
        font-weight: 700;
        font-size: 26px;
        line-height: 32px;
        text-align: center;
        letter-spacing: 0.02em;
        color: var(--white-color);
    }

    &_bottom-desc {
        font-family: 'Helvetica Neue Bold';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 30px;
        text-align: center;
        letter-spacing: 0.02em;
        color: var(--white-color);
    }

    &__bottom-wrap {
        height: 35%;
        display: flex;
        width: 90%;
        margin: 0 auto;
        justify-content: center;
        align-items: flex-end;
    }
}
