.interest-button {
    width: 41%;
    min-width: 113px;
    border: 1px solid #9b9b9b;
    border-radius: 100px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 50px;
    padding: 0 12px;
    margin-bottom: 15px;
    cursor: pointer;

    &_title {
        font-family: 'Helvetica Neue';
        font-style: normal;
        font-weight: 400;
        font-size: 10px;
        line-height: 13px;
        display: flex;
        align-items: center;
        letter-spacing: 1.5px;
        max-width: 100px;
        min-width: 53px;
    }
}

.active {
    border: 1px solid var(--black-color);
    justify-content: space-between;
}
