.impact-container {
    background-color: var(--white-color);
    height: 100%;

    &__content {
        padding: 35px 16px;
    }

    &__header {
        display: flex;
        align-items: center;
        padding: 11.5px 23px 7px 17px;
        border-bottom: 2px solid #ebebeb;
        justify-content: space-between;
    }

    &__header-right {
        display: flex;
        align-items: center;
        padding-right: 7px;
    }

    &_profile-image {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        margin-right: 15px;
        object-fit: contain;
        background-color: white;
    }

    &_profile-title {
        font-family: 'Helvetica Neue Bold';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 30px;
        text-align: center;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: var(--black-color);
        padding: 3px 0px 0px 0px;
    }
    &_profile-border {
        border: 1px solid #d9d9d9;
        height: 17px;
        width: 0;
        margin: 1px 10px 0 7px;
    }

    &_points-text {
        font-family: 'Helvetica Neue';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        margin-top: 2px;
        line-height: 30px;
        text-align: center;
        color: var(--black-color);
        margin-right: 5px;
    }

    &__header-left {
        cursor: pointer;
        display: flex;
        align-items: center;
    }
    &_image {
        width: 22px;
        height: 22px;
        margin-right: 5px;
    }

    &__switch-container {
        cursor: pointer;
        display: flex;
    }

    &__switch-wrapper {
        display: flex;
        width: 100%;
        justify-content: space-between;
        background-color: #e6eceb;
        border-radius: 6px;
        padding: 2px;
    }

    &__switcher {
        width: 50%;
        text-align: center;
        padding: 16px;
        border-radius: 6px;
    }

    &_switcher-text {
        color: black;
    }

    &_project-title {
        font-family: 'Helvetica Neue Bold';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 20px;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: var(--black-color);
        margin-top: 30px;
    }

    &__cards-wrapper {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: 20px;
    }

    &__no-projects {
        font-family: 'Helvetica Neue';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 26px;
        text-align: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #9b9b9b;
    }

    &__no-projects-img {
        position: relative;
        left: 100%;
        transform: translateX(-100%);

    }
}

.active-switcher {
    background-color: var(--white-color);
}
