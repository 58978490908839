@import './fonts.scss';

:root {
    --primary-color: #1e4037;
    --white-color: #ffffff;
    --black-color: #000000;
    --grey-color: #9b9b9b;
    --light-blue-color: #83a0b0;
    --light-gray-color: #ebebeb;
}
