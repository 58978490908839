* {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

.animated {
	position: relative;
	z-index: 3;
}

.shop-screen_disabled {
	width: 100%;
	&__input-container {
		display: flex;
		flex-direction: column;
		align-items: center;
		height: auto;
	}
	&__inputs {
		position: relative;
		margin: 32px 0 32px 0;
		display: flex;
		gap: 8px;
		padding: 5px;
		justify-content: center;
		align-items: center;
		height: 55px;
		width: 740px;
		max-width: 771px;
		min-width: 300px;
		@media (max-width: 770px) {
			max-width: 440px;
			width: 440px;
		}
		@media (max-width: 585px) {
			max-width: 390px;
			width: 390px;
		}
		@media (max-width: 425px) {
			max-width: 330px;
			width: 330px;
		}
	}
	&__input {
		position: relative;
		height: 38px;
		border-radius: 32px;
		padding: 0 0 0 50px;
		border: 1px solid #000;
		width: 100%;
		font-size: 14px;
		@media screen and (max-width: 650px) {
			font-size: 0px;
		}
	}
	&__input {
		outline: none;
	}
	&__buttons {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-wrap: wrap;
		gap: 12px;
		margin-bottom: 24px;
	}
	&_icon {
		position: absolute;
		left: 25px;
		margin: 2px 0 0 0;
	}
	&__photos-container {
		max-width: 1440px;
		position: fixed;
		left: 11.5%;
		margin-right: auto;
		margin-left: auto;
		padding: 0;
		@media screen and (min-width: 1440px) {
			max-width: 1440px;
			margin-right: auto;
			margin-left: auto;
		}
	}
	&__photos {
		display: flex;
		flex-flow: row wrap;
		align-items: center;
		justify-content: flex-start;
		margin-right: 24px;
		margin-bottom: 64px;
		margin-left: 24px;
		grid-column-gap: 24px;
		grid-row-gap: 24px;
		@media screen and (min-width: 1480px) {
			max-width: 1440px;
			margin-right: auto;
			margin-left: auto;
		}
		@media screen and (max-width: 767px) {
			grid-column-gap: 16px;
			grid-row-gap: 16px;
		}
		@media screen and (max-width: 335px) {
			grid-column-gap: 16px;
			grid-row-gap: 16px;
		}
	}
	&_photo {
		cursor: pointer;
		object-fit: cover;
		min-height: 100%;
		min-width: 100%;
	}
	&__img-container {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		overflow: hidden;
	}
	&__img-container picture {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100%;
		width: 100%;
	}
	&__photo-container {
		padding: 0 15px;
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;
		background: #004236;
		height: 270px;
		margin-bottom: 14px;
		flex-basis: 100%;
		max-width: 23%;
		@media only screen and (max-width: 1010px) {
			max-width: 31%;
			min-width: 31%;
		}
		@media only screen and (max-width: 750px) {
			max-width: 48%;
			min-width: 48%;
		}
		@media only screen and (max-width: 500px) {
			max-width: 100%;
			min-width: 100%;
		}
	}
	&__photo-name {
		position: relative;
		font-style: normal;
		font-family: "Helvetica Neue Bold";
		font-weight: 700;
		font-size: 22px;
		line-height: 30px;
		letter-spacing: 2px;
		text-transform: uppercase;
		text-align: center;
		padding: 0 10px 25px;
		color: #fff;
		z-index: 2;
	}
	&__photo-button-img {
		background-color: white;
		border-radius: 100%;
	}
}
.shop-screen {
	&__input-container {
		display: flex;
		flex-direction: column;
		align-items: center;
		height: auto;
	}
	&__rewards-container {
		display: flex;
		align-items: center;
		border-radius: 24px;
		border: 2px solid #f7d4c7;
		&__text {
			width: 20px;
			font-family: "Helvetica Neue Bold";
			font-style: normal;
			font-weight: 500;
			font-size: 14px;
			line-height: 20px;
			letter-spacing: 0.001em;
			color: #000000;
			margin: 0 10px 0 8px;
			@media screen and (max-width: 500px) {
				margin: 0 2px 0 2px;
			}
		}
	}
	&__inputs {
		position: relative;
		margin: 32px 0 32px 0;
		display: flex;
		gap: 8px;
		padding: 5px;
		justify-content: center;
		align-items: center;
		height: 55px;
		width: 740px;
		max-width: 771px;
		min-width: 300px;
		@media (max-width: 770px) {
			max-width: 440px;
			width: 440px;
		}
		@media (max-width: 585px) {
			max-width: 390px;
			width: 390px;
		}
		@media (max-width: 425px) {
			max-width: 330px;
			width: 330px;
		}
	}
	&__input {
		position: relative;
		height: 38px;
		border-radius: 32px;
		padding: 0 0 0 50px;
		border: 1px solid #000;
		width: 100%;
		font-size: 14px;
		@media screen and (max-width: 650px) {
			font-size: 14px;
			padding: 0 0 0 40px;
		}
		//@media (max-width: 425px){
		//  font-size: 11px;
		//}
	}
	&__input {
		outline: none;
	}
	&__buttons {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-wrap: wrap;
		gap: 12px;
		margin-bottom: 24px;
	}
	&_icon {
		position: absolute;
		left: 25px;
		margin: 2px 0 0 0;
	}
	&_search-delete {
		cursor: pointer;
		position: absolute;
		right: 125px;
		margin: 0 2px 0 0;
		font-size: 14px;
	}
	&__photos-container {
		//display: flex;
		max-width: 1440px;
		position: relative;
		margin-right: auto;
		margin-left: auto;
		padding: 0;
		@media screen and (min-width: 1440px) {
			max-width: 1440px;
			margin-right: auto;
			margin-left: auto;
		}
		//@media screen and (max-width: 590px){
		//  flex-wrap: wrap;
		//}
	}
	&__photos {
		//width: -webkit-fill-available;
		display: flex;
		flex-flow: row wrap;
		align-items: center;
		justify-content: flex-start;
		margin-right: 24px;
		margin-bottom: 64px;
		margin-left: 24px;
		grid-column-gap: 24px;
		grid-row-gap: 24px;
		@media screen and (min-width: 1480px) {
			max-width: 1440px;
			margin-right: auto;
			margin-left: auto;
		}
		@media screen and (max-width: 767px) {
			grid-column-gap: 16px;
			grid-row-gap: 16px;
		}
		@media screen and (max-width: 335px) {
			grid-column-gap: 16px;
			grid-row-gap: 16px;
		}
	}
	&_photo {
		cursor: pointer;
		object-fit: cover;
		min-height: 100%;
		min-width: 100%;
	}
	&__img-container {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		overflow: hidden;
	}
	&__img-container picture {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100%;
		width: 100%;
	}
	&__photo-container-false {
		padding: 0 15px;
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;
		background: #004236;
		height: 270px;
		margin-bottom: 14px;
		flex-basis: 100%;
		max-width: 23%;
		@media only screen and (max-width: 1010px) {
			max-width: 31%;
			min-width: 31%;
		}
		@media only screen and (max-width: 750px) {
			max-width: 48%;
			min-width: 48%;
		}
		@media only screen and (max-width: 500px) {
			max-width: 100%;
			min-width: 100%;
		}
	}
	&__photo-container-true {
		padding: 0 15px;
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;
		background: #004236;
		height: 270px;
		margin-bottom: 14px;
		flex-basis: 100%;
		max-width: 31%;
		@media only screen and (max-width: 1140px) {
			max-width: 45%;
		}
		@media only screen and (max-width: 768px) {
			margin: 0 auto;
			max-width: 80%;
		}
		@media only screen and (max-width: 590px) {
			max-width: 100%;
			min-width: 100%;
		}
	}
	&__photo-name {
		cursor: pointer;
		position: relative;
		font-style: normal;
		font-family: "Helvetica Neue Bold";
		font-weight: 700;
		font-size: 22px;
		line-height: 30px;
		letter-spacing: 2px;
		text-transform: uppercase;
		text-align: center;
		padding: 0 10px 25px;
		color: #fff;
		z-index: 2;
	}
	&__photo-button-img {
		background-color: white;
		border-radius: 100%;
	}
}
