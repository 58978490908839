.social-button {
    cursor: pointer;
    background-color: var(--white-color);
    border: none;
    margin-bottom: 15px;
    border-radius: 100px;
    display: flex;
    align-items: center;
    padding: 16px;

    &_title {
        font-family: 'Helvetica Neue';
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 16px;
        text-align: center;
        letter-spacing: 2px;
        color: var(--black-color);
        width: 90%;
    }

    &_image {
        margin: 0;
    }
}
