.preview-container {
    height: 100%;
    width: 100%;
    background-color: var(--light-blue-color);
    &__content-wrapper {
        display: flex;
        flex-direction: column;
        height: 90%;
        justify-content: center;
        @media screen and (max-height: 700px) {
            height: 83%;
        }
    }

    &__bottom-wrapper {
        width: 80%;
        margin: 0 auto;
        margin-top: 40px;
    }
}

.slider-container {
    width: 100%;
    @media screen and (max-height: 700px) {
        height: 560px;
    }
}

.slick-track {
    width: 100%;
    display: flex;
}

.slick-slide {
    width: 33%;
    outline: none !important;
}

.slick-slide > div > div {
    outline: none;
    text-align: center;
}

.slider-item-text {
    font-family: 'Helvetica Neue';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 26px;
    text-align: center;
    letter-spacing: 2px;
    color: var(--white-color);
    max-width: 200px;
    margin: 0 auto;
    margin-top: 38px;
}

.slick-dots {
    display: flex !important;
    width: 40%;
    margin: 0 auto;
    justify-content: center;
    margin-top: 30px;
}

.slick-dots > li {
    width: 30%;
    list-style: none;
}

.slick-dots > li > button {
    color: transparent;
    background-color: transparent;
    border: 1px solid black;
    border-radius: 50%;
    width: 12px;
    height: 12px;
    padding: 0;
}

.slick-dots > .slick-active > button {
    background-color: black;
    border: 1px solid black;
}
